/* eslint-disable @typescript-eslint/no-explicit-any */
import { Subscription } from '@containers/Settings-And-Members/pages/billing-and-invoices/types';
import { SingleQuicksaveLinkEntity } from '@containers/Settings-And-Members/services/entity/account.entity';
import dayjs from 'dayjs';
import isEmpty from 'lodash-es/isEmpty';
import config from '../config';
class Utils {
  public static formatColor(rgb: string, opacity: string) {
    return `rgba(${rgb}, ${opacity})`;
  }

  static isPlanExpired = (subscription: Subscription | null) => {
    if (!subscription) {
      return false;
    }
    const onPlan = subscription && !isEmpty(subscription);
    return onPlan && dayjs().isAfter(subscription.currentTermEnd, 'day');
  };

  public static isValidUrl = (url: string) => {
    const pattern =
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

    return pattern.test(url);
  };

  public static addhttp = (reqUrl: string) => {
    if (!/^(f|ht)tps?:\/\//i.test(reqUrl)) {
      const bookmarkUrl = `https://${reqUrl}`;

      return bookmarkUrl;
    }
  };

  public static setImageUrl = (image: string | null | undefined) => {
    if (!image) return '';
    if (image.startsWith('//')) return image;
    try {
      new URL(image);
      return image;
    } catch {
      return `${config.S3.endpoint}${image}`;
    }
  };

  public static getOS = () => {
    if (window.navigator && window.navigator.userAgent.toLowerCase().includes('macintosh')) {
      return 'Mac';
    }
    return 'Win';
  };
}

export default Utils;

export function shallowCompare<T>(objA: T, objB: T) {
  if (Object.is(objA, objB)) {
    return true;
  }

  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
    return false;
  }

  const keysA = Object.keys(objA);
  if (keysA.length !== Object.keys(objB).length) {
    return false;
  }

  for (let i = 0; i < keysA.length; i++) {
    if (
      !Object.prototype.hasOwnProperty.call(objB, keysA[i] as string) ||
      !Object.is(objA[keysA[i] as keyof T], objB[keysA[i] as keyof T])
    ) {
      return false;
    }
  }
  return true;
}

export const urlPattern = new RegExp(
  '(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?'
);

export const getFormattedUrl = (url: string) => {
  const httpUrl = /^(?:http):\/\//i.test(url);
  const httpsURL = Utils.addhttp(url.replace(/(^\w+:|^)\/\//, '')) as string;
  return httpUrl ? url : httpsURL;
};

interface TextSegment {
  content: string;
  type: 'string' | 'link';
}

export const splitStringWithMetadata = (inputString: string): TextSegment[] => {
  // Regular expression to match URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Array to store text segments with metadata
  const result: TextSegment[] = [];

  let match: RegExpExecArray | null;
  let lastIndex = 0;

  // Iterate through matches
  while ((match = urlRegex.exec(inputString)) !== null) {
    // Extract the plain text before the link
    const plainText: string = inputString.substring(lastIndex, match.index);
    if (plainText) {
      result.push({ content: plainText, type: 'string' });
    }

    // Extract the link
    const link: string = match[0];
    result.push({ content: link, type: 'link' });

    // Update the lastIndex
    lastIndex = urlRegex.lastIndex;
  }

  // Add any remaining plain text after the last link
  const remainingText: string = inputString.substring(lastIndex);
  if (remainingText) {
    result.push({ content: remainingText, type: 'string' });
  }

  return result;
};

export const replaceWindowURL = (url: string) => {
  console.trace('replaceWindowURL: ', url);
  // should be allowed to redirect to external links
  // eslint-disable-next-line no-restricted-syntax
  window.location.href = url;
};

export const getCurrentSubdomain = () => {
  return window.location.hostname.replace(import.meta.env.REACT_APP_BASE_DOMAIN, '').replace('.', '');
};

// clears subproject indexedDB
export const clearIndexedDB = () => {
  const req = indexedDB.deleteDatabase('sub_project_store');
  req.onsuccess = () => {
    console.log('Deleted database successfully');
  };
  req.onerror = () => {
    console.log("Couldn't delete database");
  };
  req.onblocked = () => {
    console.log("Couldn't delete database due to the operation being blocked");
  };
};

export function getHostnameFromURL(url: string): string {
  const regex = /^(?:https?:\/\/)?(?:www\.)?([^\/:]+)/;
  const match = url.match(regex);
  return match ? match[1] : url;
}

export const QUICK_SAVE_PREFIX = 'quick_save';
export const mapQuickSaveWithCustomID = (quickSave: SingleQuicksaveLinkEntity[]) => {
  return quickSave.map((link) => {
    return {
      ...link,
      id: QUICK_SAVE_PREFIX + '_' + link._id,
    };
  });
};

interface FilePickerAcceptType {
  description?: string;
  accept: Record<string, string[]>;
}

interface FilePickerOptions {
  multiple?: boolean;
  types?: FilePickerAcceptType[];
}

interface FileHandle {
  getFile: () => Promise<File>;
}

export function showOpenFilePickerPolyfill(options: FilePickerOptions): Promise<FileHandle[]> {
  return new Promise((resolve) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = options.multiple ?? false;
    input.accept =
      options.types
        ?.map((type) => type.accept)
        .flatMap((inst) => Object.keys(inst).flatMap((key) => inst[key]))
        .join(',') || '';

    console.log(input.accept);

    input.addEventListener('change', () => {
      resolve(
        Array.from(input.files || []).map((file) => ({
          getFile: async () => new Promise<File>((resolve) => resolve(file)),
        }))
      );
    });

    input.click();
  });
}

export const isDesktopBrowser = () => {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
  if (/android/i.test(userAgent) || /iPhone|iPad|iPod/i.test(userAgent)) {
    return false;
  }

  return true;
};

export function buildQueryString(
  params: Record<string, string | number | boolean | (string | number | boolean)[] | undefined | null>
): string {
  const queryString = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value === undefined || value === null) {
      return;
    }

    if (Array.isArray(value)) {
      // If the value is an array, append each item separately
      value.forEach((item) => {
        queryString.append(key, String(item));
      });
    } else {
      // If the value is a primitive, append it directly
      queryString.append(key, String(value));
    }
  });

  return queryString.toString();
}

/**
 * Removes all properties with undefined values from an object
 *
 * @template T - The type of the object
 * @param {T} obj - The source object to remove undefined values from
 * @returns {T} A new object with all undefined values removed
 *
 * @example
 * const input = { a: 1, b: undefined, c: 'test' };
 * const result = removeUndefined(input);
 * // result: { a: 1, c: 'test' }
 */
export function removeUndefined<T extends object>(obj: T): T {
  return Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== undefined)) as T;
}
